import "../App.css";
import React, { Component, createRef } from "react";
import $ from "jquery";
import axios from "axios";
import { BP3D } from "../engine/blueprint3d.js";
import { INIT_STRUCTURE, NEW_STRUCTURE } from "../Structures.js";
import {
  BASE_URL,
  PLANNAR_ENDPOINT,
  CRYPTOJS_KEY,
  PLANNAR_SHARE_ENDPOINT,
  MIRRAR_PAGE_URL,
} from "../Constants.js";
import Button from "react-bootstrap/Button";
import {
  FaWalking,
  FaCamera,
  FaDownload,
  FaUndo,
  FaRedo,
} from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ContextMenu from "./ContextMenu.jsx";
import TextureContextMenu from "./TextureContextMenu.jsx";
import WallContextMenu from "./WallContextMenu.jsx";
import ColorCodeContextMenu from "./ColorCodeContextMenu.jsx";
import SaveModal from "./SaveLoadModal/SaveFile/SaveModal.jsx";
import LoadModal from "./SaveLoadModal/LoadFile/LoadModal.jsx";

import { inject, observer } from "mobx-react";
import ZoomIn from "./SvgComponents/ZoomIn.jsx";
import ZoomOut from "./SvgComponents/ZoomOut.jsx";
import Loaction from "./SvgComponents/Loaction.jsx";
import UpEllipse from "./SvgComponents/UpEllipse.jsx";
import DownEllipse from "./SvgComponents/DownEllipse.jsx";
import Ellipse from "./SvgComponents/Ellipse.jsx";
import LeftEllipse from "./SvgComponents/LeftEllipse.jsx";
import RightEllipse from "./SvgComponents/RightEllipse.jsx";
import ItemModal from "./AddItems/ItemModal.jsx";
import FloorTextureModal from "./Textures/FloorTextureModal.jsx";
import WallTextureModal from "./Textures/WallTextureModal.jsx";
import MoveIcon from "./SvgComponents/MoveIcon";
import DrawIcon from "./SvgComponents/DrawIcon";
import TrashIcon from "./SvgComponents/TrashIcon";
import CircleTickIcon from "./SvgComponents/CircleTickIcon";
import ProductByMirrAR from "./SvgComponents/ProductByMirrAR";
import Object from "./SvgComponents/Object";
import Header from "./Header/Header";
import CloseModal from "./SaveLoadModal/SaveFile/CloseModal";
import { withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";
import CloseIcon from "./SvgComponents/CloseIcon";
import LockIcon from "./SvgComponents/LockIcon";
import UnlockIcon from "./SvgComponents/UnlockIcon";
import AddItemIcon from "./SvgComponents/AddItemIcon";
import { IoIosHelpCircle } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import { isMobile, isTablet } from "react-device-detect";
import Swal from "sweetalert2";
import MixpanelService from "../mixpanel/mixpanelService";
import { BiAddToQueue } from "react-icons/bi";

@inject("store")
@observer
class BlueprintPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      blueprint3d: {},
      addClickListener: false,
      currentStateName: "Design",
      blob: "",
      catalogues: [],
      catalogId: null,
      campaignEntityCards: [],
      loading: false,
      totalPages: 1,
      currentPage: 1,
      saved: false,
      highlightedIndex: 0,
      floorTextureList: [],
      wallTextureList: [],
      mounted: false,
      walkthrough: false,
      hasViewKey: false,
      previewImage: null,
      showPreviewModal: false,
      textureTouchEvent: true,
      helpModal: false,
      showCaptureModal: false,
      isOritentationLandscape: false,
      dataLoaded: false,
      // history: [{ items: [] }],
      // currentIndex: 0,
    };
    this.navRef = createRef();
    this.engine = this.engine.bind(this);
    this.setDesignState = this.setDesignState.bind(this);
    this.cameraButtons = this.cameraButtons.bind(this);
    this.contextMenu = this.contextMenu.bind(this);
    this.modalEffects = this.modalEffects.bind(this);
    this.sideMenu = this.sideMenu.bind(this);
    this.textureSelector = this.textureSelector.bind(this);
    this.initItems = this.initItems.bind(this);
    this.saveFile = this.saveFile.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.modalSave = this.modalSave.bind(this);
    this.handelCapture = this.handelCapture.bind(this);
    this.closePreview = this.closePreview.bind(this);
    this.textureDisplay = this.textureDisplay.bind(this);
    this.isLandscape = this.isLandscape.bind(this);
  }

  // async addTodo() {
  //   var val = await this.state.blueprint3d.model.exportSerialized();
  //   console.log(val);
  //   const { history, currentIndex } = this.state;
  //   // const currentItems = history[currentIndex].items;
  //   // const newItems = [...currentItems, { id: crypto.randomUUID(), name: val }];
  //   const newItems = [{ id: crypto.randomUUID(), name: val }];
  //   this.setState({
  //     history: [...history.slice(0, currentIndex + 1), { items: newItems }],
  //     currentIndex: currentIndex + 1,
  //   });
  // };

  async saveFile(text = null) {
    const isLoggedIn = this.props.store.getLoggedIn;
    if (this.state.blueprint3d.model !== undefined) {
      $("#loader").css("display", "flex");
      var data = this.state.blueprint3d.model.exportSerialized();
      // var a = window.document.createElement("a");
      // var blob = new Blob([data], {
      //   type: "text",
      // });
      // const viewerDiv = document.getElementById("viewer");
      // const canvasElement = viewerDiv.querySelector("canvas");

      // await html2canvas(canvasElement).then(canvas => {
      // const dataURL = canvasElement.toDataURL('image/png');
      // localStorage.setItem("image_file", dataURL);
      // });
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CRYPTOJS_KEY
      ).toString();
      if (isLoggedIn) {
        this.setState({ blob: encryptedData });
        $("#loader-text").text("SAVING");

        if (text === "redirect") {
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 3000);
        }
      } else if (text === "login") {
        localStorage.setItem("plannar_data", encryptedData);
        this.props.history.push("/login");
      } else if (text === "signup") {
        localStorage.setItem("plannar_data", encryptedData);
        this.props.history.push("/signup");
      }
      // a.href = window.URL.createObjectURL(blob);
      // a.download = "design.blueprint3d";
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
    }
  }

  async loadFile(uri, id) {
    try {
      if (uri !== null && uri !== "") {
        // let res = await axios.get(BASE_URL + ASSETS + uri);
        // this.state.blueprint3d.model.loadSerialized(JSON.stringify(uri));
        this.props.history.push("/project/" + id);
        this.state.blueprint3d.model.loadSerialized(uri);
      } else {
        console.log("uri is null");
      }
    } catch (e) {
      console.log(e);
    }
  }

  /*
   * Camera Buttons
   */
  cameraButtons(blueprint3d) {
    var orbitControls = blueprint3d.three.controls;
    var three = blueprint3d.three;
    var camera = blueprint3d.three.getCamera();
    let self = this;

    const moveButton = document.getElementById("move-button");
    const controlArea = document.getElementById("control-area");
    const controlRadius = controlArea.offsetWidth / 2; // Radius of the control area
    const buttonRadius = moveButton.offsetWidth / 2; // Radius of the move button
    const maxMovement = controlRadius - buttonRadius; // Max movement from center
    let isDragging = false;
    let moveInterval = null;
    let startX = 0;
    let startY = 0;
    let targetX = 0;
    let targetY = 0;

    var panSpeed = 12;
    var directions = {
      UP: 1,
      DOWN: 2,
      LEFT: 3,
      RIGHT: 4,
    };

    controlArea.addEventListener("pointerdown", startDrag);
    window.addEventListener("pointerup", stopDrag);
    // window.addEventListener("pointermove", drag);

    function init() {
      // Camera controls
      // $(".zoom-in").click(zoomIn);
      // $(".zoom-in").on("pointerdown", zoomIn);
      // $(".zoom-out").click(zoomOut);
      // $(".zoom-out").on("pointerdown", zoomOut);

      // $(".zoom-in").dblclick(preventDefault);
      // $(".zoom-out").dblclick(preventDefault);

      $(".inperson-view").click(inPersonView);
      $(".inperson-view").on("touchend", inPersonView);
      $(".inperson-view").dblclick(preventDefault);

      $(".reset-view").on('click', function () {
        MixpanelService.trackEvent("Reset View");
        three.centerCamera();
      });

      $(".reset-view").on("touchend", function (event) {
        MixpanelService.trackEvent("Reset View");
        three.centerCamera();
      });
    }

    function preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    function pan(direction) {
      switch (direction) {
        case directions.UP:
          orbitControls.panXY(0, panSpeed);
          break;
        case directions.DOWN:
          orbitControls.panXY(0, -panSpeed);
          break;
        case directions.LEFT:
          orbitControls.panXY(panSpeed, 0);
          break;
        case directions.RIGHT:
          orbitControls.panXY(-panSpeed, 0);
          break;
        default:
          break;
      }
    }

    // function zoomIn(e) {
    //   e.preventDefault();
    //   orbitControls.dollyIn(1.1);
    //   orbitControls.update();
    // }

    // function zoomOut(e) {
    //   // eslint-disable-next-line no-unused-expressions
    //   e.preventDefault;
    //   orbitControls.dollyOut(1.1);
    //   orbitControls.update();
    // }

    function inPersonView(e) {
      e.preventDefault();
      if (!self.state.walkthrough) {
        MixpanelService.trackEvent("Room Scale View");
        orbitControls.dollyIn(8);
        orbitControls.target.y = 137.16;
        orbitControls.maxPolarAngle = Math.PI * 0.95;
        orbitControls.update();
      } else {
        MixpanelService.trackEvent("Normal View");
        three.centerCamera();
        orbitControls.maxPolarAngle = Math.PI / 2;
      }
      self.setState((prevState) => ({
        walkthrough: prevState.walkthrough === false ? true : false,
      }));
    }

    function startDrag(e) {
      e.preventDefault();
      isDragging = true;
      const rect = controlArea.getBoundingClientRect();
      startX = e.clientX - rect.left;
      startY = e.clientY - rect.top;
      targetX = startX;
      targetY = startY;
      moveInterval = setInterval(continuousMove, 50);
      window.addEventListener("pointermove", drag);
    }

    function stopDrag(e) {
      e.preventDefault();
      isDragging = false;
      moveButton.style.top = "50%";
      moveButton.style.left = "50%";
      moveButton.style.transform = "translate(-50%, -50%)";
      clearInterval(moveInterval);
      moveInterval = null;
      window.removeEventListener("pointermove", drag);
    }

    function continuousMove() {
      const cameraSpeed = 5;

      if (Math.abs(targetX - controlRadius) > Math.abs(targetY - controlRadius)) {
        if (targetX > controlRadius) {
          // camera.position.x += cameraSpeed;
          orbitControls.panXY(-panSpeed, 0);
        } else {
          // camera.position.x -= cameraSpeed;
          orbitControls.panXY(panSpeed, 0);
        }
      } else {
        if (targetY > controlRadius) {
          // camera.position.y -= cameraSpeed;
          orbitControls.panXY(0, -panSpeed);
        } else {
          // camera.position.y += cameraSpeed;
          orbitControls.panXY(0, panSpeed);
        }
      }
    }

    function drag(e) {
      if (!isDragging) return;
      e.preventDefault();

      const rect = controlArea.getBoundingClientRect();
      const currentX = e.clientX - rect.left;
      const currentY = e.clientY - rect.top;

      // Calculate the change in position since the last drag event
      const deltaX = currentX - targetX;
      const deltaY = currentY - targetY;

      // Update target coordinates
      targetX = currentX;
      targetY = currentY;


      let targetX_1 = currentX;
      let targetY_1 = currentY;

      const distanceX = targetX_1 - controlRadius;
      const distanceY = targetY_1 - controlRadius;
      const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

      if (distance > maxMovement) {
        const angle = Math.atan2(distanceY, distanceX);
        targetX_1 = controlRadius + Math.cos(angle) * maxMovement;
        targetY_1 = controlRadius + Math.sin(angle) * maxMovement;
      }

      // Update the move button position
      moveButton.style.left = `${targetX_1}px`;
      moveButton.style.top = `${targetY_1}px`;
      moveButton.style.transform = "translate(-50%, -50%)";

      // Optionally, you can also adjust camera position here if needed
    }
    init();
    this.setState({ blueprint3d: blueprint3d });
  }

  /*
   * Context menu for selected item
   */
  contextMenu(blueprint3d) {
    var self = this;
    // var scope = this;
    var selectedItem;
    var three = blueprint3d.three;

    function init() {
      $("#context-menu-delete").click(function (event) {
        selectedItem.remove();
      });
      $("#context-menu-delete").on("touchend", function (event) {
        selectedItem.remove();
      });

      three.itemSelectedCallbacks.add(itemSelected);
      three.itemUnselectedCallbacks.add(itemUnselected);

      initResize();

      $("#fixed").click(function () {
        var checked = $(this).prop("checked");
        selectedItem.setFixed(checked);
      });
    }

    function cmToIn(cm) {
      return cm / 2.54;
    }

    function inToCm(inches) {
      return inches * 2.54;
    }

    function itemSelected(item) {
      selectedItem = item;

      if ($("#items-container").is(":visible")) {
        $("#items-container").hide();
      }

      $("#context-menu-name").text(item.metadata.itemName);

      $("#item-width").val(cmToIn(selectedItem.getWidth()).toFixed(0));
      $("#item-height").val(cmToIn(selectedItem.getHeight()).toFixed(0));
      $("#item-depth").val(cmToIn(selectedItem.getDepth()).toFixed(0));
      $("#item-elevation").val(cmToIn(selectedItem.getElevation()).toFixed(0));

      if (self.state.textureTouchEvent) {
        // $("#texture-context-container").hide();
        $("#context-menu").show();
      }

      if (selectedItem.isElevationAdjustable()) {
        $("#item-elevation-div").show();
      } else {
        $("#item-elevation-div").hide();
      }

      $("#fixed").prop("checked", item.fixed);
    }

    function resize() {
      selectedItem.resize(
        inToCm($("#item-height").val()),
        inToCm($("#item-width").val()),
        inToCm($("#item-depth").val())
      );
    }

    function elevate() {
      selectedItem.elevate(inToCm($("#item-elevation").val()));
    }

    function initResize() {
      $("#item-height").change(resize);
      $("#item-width").change(resize);
      $("#item-depth").change(resize);
      $("#item-elevation").change(elevate);
    }

    function itemUnselected() {
      selectedItem = null;
      // $("texture-context-container").hide();
      $("#context-menu").hide();
    }

    init();
    this.setState({ blueprint3d: blueprint3d });
  }

  /*
   * Loading modal for items
   */
  modalEffects(blueprint3d) {
    // var scope = this;
    var itemsLoading = 0;

    this.setActiveItem = function (active) {
      // eslint-disable-next-line no-undef
      itemSelected = active;
      update();
    };

    function update() {
      if (itemsLoading > 0) {
        $("#loading-modal").show();
        // $("#texture-context-container").hide();
      } else {
        $("#loading-modal").hide();
        // $("#texture-context-container").show();
      }
    }

    function init() {
      blueprint3d.model.scene.itemLoadingCallbacks.add(function () {
        itemsLoading += 1;
        update();
      });

      blueprint3d.model.scene.itemLoadedCallbacks.add(function () {
        itemsLoading -= 1;
        update();
      });

      update();
    }

    init();
    this.setState({ blueprint3d: blueprint3d });
  }

  /*
   * Side menu
   */
  sideMenu = function (blueprint3d, floorplanControls, modalEffects) {
    // var modalEffects = modalEffectsArg;

    var ACTIVE_CLASS = "active";

    var tabs = {
      FLOORPLAN: $(".floorplan_tab"),
      SHOP: $(".items_tab"),
      DESIGN: $(".design_tab"),
    };

    // var scope = this;
    var stateChangeCallbacks = $.Callbacks();

    var states = {
      DEFAULT: {
        div: $("#viewer"),
        tab: tabs.DESIGN,
        name: "Design",
      },
      FLOORPLAN: {
        div: $("#floorplanner"),
        tab: tabs.FLOORPLAN,
        name: "Floorplan",
      },
      SHOP: {
        div: $("#items-container"),
        tab: tabs.SHOP,
        name: "Shop",
      },
    };

    // sidebar state
    var currentState = states.FLOORPLAN;

    function init() {
      for (var tab in tabs) {
        var elem = tabs[tab];
        elem.click(tabClicked(elem));
      }

      $("#update-floorplan").click(floorplanUpdate);
      function reset() {
        // $("texture-context-container").hide();
        $("#wallTextures").hide();
        $("#floorTexturesDiv").hide();
      }
      stateChangeCallbacks.add(reset);

      initLeftMenu();

      blueprint3d.three.updateWindowSize();
      handleWindowResize();

      initItems();

      setCurrentState(states.DEFAULT, true);
    }

    function floorplanUpdate() {
      setCurrentState(states.DEFAULT);
    }

    function tabClicked(tab) {
      return function () {
        // Stop three from spinning
        initItems();
        blueprint3d.three.stopSpin();

        // Selected a new tab
        for (var key in states) {
          var state = states[key];
          if (state.tab === tab) {
            setCurrentState(state);
            break;
          }
        }
      };
    }

    var getCurrentState = () => {
      if (this.state.currentStateName === "Design") {
        return states.DEFAULT;
      }
      if (this.state.currentStateName === "Floorplan") {
        this.setState((prevState) => ({ walkthrough: false }));
        return states.FLOORPLAN;
      }
      if (this.state.currentStateName === "Shop") {
        if ($("#items-container").hide()) {
          $("#items-container").show();
        }
        if ($("#floorTexturesDiv").is(":visible")) {
          $("#floorTexturesDiv").hide();
        }
        if ($("#wallTextures").is(":visible")) {
          $("#wallTextures").hide();
        }
        if ($("#texture-context-menu").is(":visible")) {
          $("#texture-context-menu").hide();
        }
        return states.SHOP;
      }
      return null;
    };

    var updateState = (newState) => {
      this.setState({ currentStateName: newState.name });
    };

    function setCurrentState(newState, firstTime) {
      currentState = getCurrentState();
      firstTime = firstTime || false;
      if (!firstTime && currentState.name === newState.name) {
        return;
      }

      // show the right tab as active
      if (currentState.name !== newState.name) {
        if (currentState.tab != null) {
          currentState.tab.removeClass(ACTIVE_CLASS);
        }
        if (newState.tab != null) {
          newState.tab.addClass(ACTIVE_CLASS);
        }
      }

      if (currentState.name === newState.name) {
        newState.tab.addClass(ACTIVE_CLASS);
      }

      // set item unselected
      if (firstTime || newState.name !== "Design") {
        blueprint3d.three.getController().setSelectedObject(null);
      }

      // show and hide the right divs
      // currentState.div.hide();
      // newState.div.show();
      if (newState.name === "Design") {
        MixpanelService.trackEvent("Design Tab");
        $("#viewer").show();
        $("#camera-controls-top").show();
        $("#camera-controls-bottom").show();
        $("#floorplanner").hide();
        $("#items-container").hide();
        // $("#texture-context-container").hide();
      } else if (newState.name === "Floorplan") {
        MixpanelService.trackEvent("Floorplan Tab");
        $("#viewer").hide();
        $("#camera-controls-top").hide();
        $("#camera-controls-bottom").hide();
        $("#floorplanner").show();
        $("#items-container").hide();
        // $("#texture-context-container").hide();
      } else {
        MixpanelService.trackEvent("Item Tab");
        $("#viewer").show();
        $("#camera-controls-top").show();
        $("#camera-controls-bottom").show();
        $("#floorplanner").hide();
        $("#items-container").show();
        // $("#texture-context-container").hide();
      }

      if ($("#texture-context-menu").is(":visible")) {
        $("#texture-context-menu").hide();
      }

      if ($("#wall-context-menu").is(":visible")) {
        $("#wall-context-menu").hide();
      }

      if ($("#color-context-menu").is(":visible")) {
        $("#color-context-menu").hide();
      }

      // custom actions
      if (newState === states.FLOORPLAN) {
        floorplanControls.handleWindowResize();
        floorplanControls.updateFloorplanView();
      }

      if (currentState === states.FLOORPLAN) {
        blueprint3d.model.floorplan.update();
      }

      if (newState === states.DEFAULT) {
        blueprint3d.three.updateWindowSize();
      }

      // set new state
      handleWindowResize();
      currentState = newState;
      updateState(newState);

      stateChangeCallbacks.fire(newState);

      //change mobx state-active-tab
    }

    function initLeftMenu() {
      $(window).resize(handleWindowResize);
      handleWindowResize();
    }

    function handleWindowResize() {
      // $(".sidebar").height(window.innerHeight);
      // $("#add-items").height(window.innerHeight);
    }

    var initItems = () => {
      this.initItems(blueprint3d, setCurrentState);
    };
    if (this.state.mounted) {
      init();
    }
    this.setState({ blueprint3d: blueprint3d });
  };

  setSelectedCardData = (data) => {
    this.initItems(this.state.blueprint3d, this.setDesignState, data);
  };

  initItems(blueprint3d, setCurrentState, selectedCardData) {
    $("#add-items").find(".add-item").off("click");
    var data = selectedCardData;
    if (data != null) {
      if ($("#items-container").is(":visible")) {
        $("#items-container").hide();
      }
      if ($("#texture-context-menu").is(":visible")) {
        $("#texture-context-menu").hide();
      }

      if ($("#wall-context-menu").is(":visible")) {
        $("#wall-context-menu").hide();
      }

      if ($("#color-context-menu").is(":visible")) {
        $("#color-context-menu").hide();
      }
      $("#loader").css("display", "flex");
      var modelUrl = data.modelUrl;
      var itemType = parseInt(data.itemType);
      var itemName = data.itemName;

      var metadata = {
        itemName: itemName,
        resizable: true,
        modelUrl: modelUrl,
        itemType: itemType,
      };

      try {
        blueprint3d.model.scene.addItem(itemType, modelUrl, metadata);
      } catch (error) {
        $("#loader").css("display", "none");
      }

      setCurrentState(
        {
          div: $("#viewer"),
          tab: $("#design_tab"),
          name: "Design",
        },
        false
      );
      // $("#loader").css("display", "none");
    }
  }

  /*
   * Change floor and wall textures
   */
  textureSelector(blueprint3d, sideMenu) {
    let self = this;
    // var scope = this;
    var three = blueprint3d.three;
    // var isAdmin = isAdmin;

    var currentTarget = null;
    var textureUrl = "";
    var textureStretch = "";
    var textureScale = "";
    var textureWidth = "";
    var textureHeight = "";
    var colorCode = "";
    var selectedWall = null;
    const resetColorButton = document.getElementById("reset-color");

    function initTextureSelectors() {
      $(".texture-select-thumbnail").off("click");
      hideTextureMenu();
      // $(".texture-select-thumbnail").click(function (e) {
      $("#apply-wall-height").click(function (e) {
        const wallHeight = $("#wall-height").val();
        selectedWall.wall.height = wallHeight;
        self.state.blueprint3d.model.floorplan.update();
      });

      $("#apply-color").click(function (e) {
        colorCode = $("#color-code").val();
        try {
          $("#loader").css("display", "flex");
          currentTarget.setTexture(
            textureUrl,
            textureStretch,
            textureScale,
            textureWidth,
            textureHeight,
            colorCode
          );
        } catch (error) {
          $("#loader").css("display", "none");
        }
      });

      $(".productList-card").on(
        "click",
        ".texture-select-thumbnail",
        function (e) {
          // $(this).addClass("texture-active");
          hideTextureMenu();
          // $(".texture-select-thumbnail").not(this).removeClass("texture-active");
          textureUrl = $(this).attr("texture-url");
          textureStretch = $(this).attr("texture-stretch");
          textureScale = parseInt($(this).attr("texture-scale"));

          try {
            $("#loader").css("display", "flex");
            currentTarget.setTexture(textureUrl, textureStretch, textureScale);
          } catch (error) {
            $("#loader").css("display", "none");
          }

          $("#apply-texture").click(function (e) {
            // if ($("#floorTexturesDiv").is(":visible") || textureType === "tile") {
            textureWidth = $("#texture-width").val();
            textureHeight = $("#texture-height").val();
            try {
              $("#loader").css("display", "flex");
              currentTarget.setTexture(
                textureUrl,
                textureStretch,
                textureScale,
                textureWidth,
                textureHeight
              );
            } catch (error) {
              $("#loader").css("display", "none");
            }
            // }
          });
          e.preventDefault();
        }
      );
    }

    function hideTextureMenu() {
      if (!self.props.store.getHasViewKey) {
        $("#texture-width").val("");
        $("#texture-height").val("");
        const resetButton = document.getElementById("reset-texture");
        resetButton.click();
        resetColorButton.click();
        $("#texture-context-menu").hide();
      }
    }

    function init() {
      three.wallClicked.add(wallClicked);
      three.wallClicked.add(initTextureSelectors);
      three.floorClicked.add(floorClicked);
      three.wallClicked.add(initTextureSelectors);
      three.itemSelectedCallbacks.add(reset);
      three.wallClicked.add(initTextureSelectors);
      three.nothingClicked.add(reset);
      three.wallClicked.add(initTextureSelectors);
      // sideMenu.stateChangeCallbacks.add(reset);
      three.wallClicked.add(initTextureSelectors);
      initTextureSelectors();
    }

    function wallClicked(halfEdge) {
      if (self.state.textureTouchEvent && !self.props.store.getHasViewKey) {
        if (currentTarget !== undefined && currentTarget !== null) {
          currentTarget.removeOutline();
        }
        if ($("#items-container").is(":visible")) {
          $("#items-container").hide();
        }
        $("#wall-height").val(halfEdge.wall.height);
        $("#wallTextures").show();
        $("#wall-context-menu").show();
        $("#color-context-menu").show();
        // $("#texture-context-container").show();
        currentTarget = halfEdge;
        selectedWall = halfEdge;
        currentTarget.drawOutline();
        Swal.fire({
          text: "Wall is selected",
          position: "top",
          toast: true,
          showConfirmButton: false,
          timer: 3000, // Auto-close after 3 seconds
          customClass: "select-alert",
        });
        $("#floorTexturesDiv").hide();
        initTextureSelectors();
      }
    }

    function floorClicked(room) {
      if (self.state.textureTouchEvent && !self.props.store.getHasViewKey) {
        if (currentTarget !== undefined && currentTarget !== null) {
          currentTarget.removeOutline();
        }
        if ($("#items-container").is(":visible")) {
          $("#items-container").hide();
        }
        $("#floorTexturesDiv").show();
        // $("#texture-context-container").show();
        currentTarget = room;
        currentTarget.drawOutline();
        Swal.fire({
          text: "Floor is selected",
          position: "top",
          toast: true,
          showConfirmButton: false,
          timer: 3000, // Auto-close after 3 seconds
          customClass: "select-alert",
        });
        $("#wallTextures").hide();
        $("#wall-context-menu").hide();
        $("#color-context-menu").hide();
        initTextureSelectors();
      }
    }

    function reset() {
      if (currentTarget !== undefined && currentTarget !== null) {
        currentTarget.removeOutline();
      }
      // $("texture-context-container").hide();
      $("#wallTextures").hide();
      $("#floorTexturesDiv").hide();
      initTextureSelectors();
    }

    init();
  }

  async engine(viewKey) {
    /*
     * Floorplanner controls
     */

    var ViewerFloorplanner = function (blueprint3d) {
      var canvasWrapper = "#floorplanner";

      // buttons
      var move = "#move";
      var remove = "#delete";
      var draw = "#draw";

      var activeStlye = "btn-primary disabled";

      this.floorplanner = blueprint3d.floorplanner;

      var scope = this;

      function init() {
        $(window).resize(scope.handleWindowResize);
        scope.handleWindowResize();

        // mode buttons
        scope.floorplanner.modeResetCallbacks.add(function (mode) {
          $(draw).removeClass(activeStlye);
          $(remove).removeClass(activeStlye);
          $(move).removeClass(activeStlye);
          if (mode === BP3D.Floorplanner.floorplannerModes.MOVE) {
            $(move).addClass(activeStlye);
          } else if (mode === BP3D.Floorplanner.floorplannerModes.DRAW) {
            $(draw).addClass(activeStlye);
          } else if (mode === BP3D.Floorplanner.floorplannerModes.DELETE) {
            $(remove).addClass(activeStlye);
          }

          if (mode === BP3D.Floorplanner.floorplannerModes.DRAW) {
            $("#draw-walls-hint").show();
            scope.handleWindowResize();
          } else {
            $("#draw-walls-hint").hide();
          }
        });

        // if (
        //   scope.floorplanner.mode === BP3D.Floorplanner.floorplannerModes.MOVE
        // ) {
        // }

        $(move).click(function () {
          scope.floorplanner.setMode(BP3D.Floorplanner.floorplannerModes.MOVE);
        });
        $(move).on("touchend", function () {
          scope.floorplanner.setMode(BP3D.Floorplanner.floorplannerModes.MOVE);
        });

        $(draw).click(function () {
          scope.floorplanner.setMode(BP3D.Floorplanner.floorplannerModes.DRAW);
        });
        $(draw).on("touchend", function () {
          scope.floorplanner.setMode(BP3D.Floorplanner.floorplannerModes.DRAW);
        });

        $(remove).click(function () {
          scope.floorplanner.setMode(
            BP3D.Floorplanner.floorplannerModes.DELETE
          );
        });
        $(remove).on("touchend", function () {
          scope.floorplanner.setMode(
            BP3D.Floorplanner.floorplannerModes.DELETE
          );
        });
      }

      this.updateFloorplanView = function () {
        scope.floorplanner.reset();
      };

      this.handleWindowResize = function () {
        $(canvasWrapper).height(
          window.innerHeight - $(canvasWrapper).offset().top
        );
        scope.floorplanner.resizeView();
      };

      init();
    };

    var mainControls = function (blueprint3d) {
      function newDesign() {
        this.props.history.push("/");
        blueprint3d.model.loadSerialized(NEW_STRUCTURE);
      }

      // var loadDesign = async (event) => {
      //   let name = $("#loadFile").attr('name');
      //   let file1 = await axios.get(
      //     "http://localhost:8001/assets/" + name
      //   );
      //   // var file1 = event.target.files[0];
      //   var reader = new FileReader();
      //   reader.onload = function () {
      //     var data = reader.result;
      //     blueprint3d.model.loadSerialized(data);
      //   };
      //   reader.readAsText(file1);
      //   $("#loadFile").replaceWith($("#loadFile").val("").clone(true));

      //   try {
      //     let uri = $("#loadFile").attr("uri");
      //     console.log("uri: " + uri);
      //     if (uri !== "") {
      //       let res = await axios.get("http://localhost:8001/assets/" + uri);
      //       blueprint3d.model.loadSerialized(JSON.stringify(res.data));
      //     }
      //   } catch (e) {
      //     console.log(e);
      //   }
      // };

      // function saveDesign() {
      //   var data = blueprint3d.model.exportSerialized();
      //   var a = window.document.createElement("a");
      //   var blob = new Blob([data], {
      //     type: "text",
      //   });
      //   // setBlob(blob);
      //   a.href = window.URL.createObjectURL(blob);
      //   a.download = "design";
      //   document.body.appendChild(a);
      //   a.click();
      //   document.body.removeChild(a);
      // }

      // function setBlob(blob) {
      //   setNewBlob(blob);
      // }

      function init() {
        // $("#new").click(newDesign);
        // $("#new").on("touchend", newDesign);
        // $("#loadFile").click(loadDesign);
        // $("#loadFileLabel").on("touchend", function () {
        //   $("#loadFile").trigger("click");
        // });
        // $("#saveFile").click(saveDesign);
        // $("#saveFile").on("touchend", saveDesign);
      }

      init();
    };

    /*
     * Initialize!
     */

    var modalEffects = this.modalEffects(this.state.blueprint3d);
    var viewerFloorplanner = new ViewerFloorplanner(this.state.blueprint3d);
    // eslint-disable-next-line no-unused-vars
    var contextMenu = this.contextMenu(this.state.blueprint3d);
    var sideMenu = this.sideMenu(
      this.state.blueprint3d,
      viewerFloorplanner,
      modalEffects
    );

    // eslint-disable-next-line no-unused-vars
    var textureSelector = this.textureSelector(
      this.state.blueprint3d,
      sideMenu
    );
    // eslint-disable-next-line no-unused-vars
    var cameraButtons = this.cameraButtons(this.state.blueprint3d);
    mainControls(this.state.blueprint3d);

    // This serialization format needs work
    // Load a simple rectangle room
    if (viewKey === "") {
      this.state.blueprint3d.model.loadSerialized(INIT_STRUCTURE);
    } else {
      try {
        localStorage.removeItem("projectName");
        let token = this.props.store.getAccessToken;
        let config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        // let res = await axios.get(BASE_URL + ASSETS + viewKey);
        // let res = await axios.get(
        await axios.get(
          BASE_URL + PLANNAR_ENDPOINT + "/" + viewKey,
          config
        ).then((res) => {
          localStorage.setItem("projectName", res.data.name);
          this.setState({
            dataLoaded: true
          });
          let decryptedData = CryptoJS.AES.decrypt(
            res.data.meta_data,
            CRYPTOJS_KEY
          );
          let meta_data = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
          this.state.blueprint3d.model.loadSerialized(meta_data);
        });
        // let file1 = new Blob(res)
        // var blueprint3d = this.state.blueprint3d;
        // var reader = new FileReader();
        //   reader.onload = function () {
        //     var data = reader.result;
        //     blueprint3d.model.loadSerialized(data);
        //   };
        //   reader.readAsText(file1);
        // this.state.blueprint3d.model.loadSerialized(JSON.stringify(res.data));
      } catch (e) {
        console.log(e);
        this.setState({
          dataLoaded: true
        });
        this.state.blueprint3d.model.loadSerialized(INIT_STRUCTURE);
      }
    }
  }

  async componentDidMount() {
    // const { store } = this.props;
    this.setState({
      mounted: true,
    });
    const isLoggedIn = localStorage.getItem("accessToken");
    const currentPath = window.location.pathname;
    const hasSlug = currentPath.includes("/project/");
    const hasViewKey = currentPath.includes("/view/");
    if (isLoggedIn && !hasSlug && !hasViewKey) {
      this.props.history.push("/dashboard");
    } else if (!isLoggedIn && hasSlug) {
      this.props.history.push("/");
    }

    var loader = document.createElement("div");
    loader.id = "loader";
    loader.className = "loading-modal center";
    loader.style.display = "none";
    loader.style.zIndex = "9999";
    var loaderImage = document.createElement("img");
    loaderImage.src = "/spinner.gif";
    loader.appendChild(loaderImage);
    var loaderTitle = document.createElement("span");
    loaderTitle.id = "loader-text";
    loaderTitle.innerHTML = "LOADING";
    loader.appendChild(loaderTitle);
    document.body.appendChild(loader);

    var opts = {
      floorplannerElement: "floorplanner-canvas",
      threeElement: "#viewer",
      threeCanvasElement: "three-canvas",
      textureDir: "rooms/textures/",
      widget: false,
    };
    this.setState({ blueprint3d: new BP3D.Blueprint3d(opts) }, () => {
      if (
        this.props.viewKey !== undefined &&
        this.props.viewKey !== null &&
        this.props.viewKey !== ""
      ) {
        this.engine(this.props.viewKey);
      } else {
        this.engine("");
      }
    });
    if (hasViewKey) {
      this.setState({ hasViewKey: true });
      await this.getData();
    }

    if (this.state.currentPage === 1) {
      $(".left-arrow-icon").addClass("d-none");
    }

    window.addEventListener('resize', this.isLandscape);
  }

  componentWillUnmount() {
    this.setState({
      mounted: false,
    });
    window.removeEventListener('resize', this.isLandscape);
  }

  handleAddItemCard = (event) => {
    if ($("#items-container").is(":visible")) {
      $("#items-container").hide();
    }
    if (!$("#wallTextures").is(":visible")) {
      $("#wall-context-menu").hide();
      $("#color-context-menu").hide();
    }
  };

  setDesignState(newState, firstTime) {
    firstTime = firstTime || false;
    var ACTIVE_CLASS = "active";

    $("#items_tab").removeClass(ACTIVE_CLASS);
    $("#design_tab").addClass(ACTIVE_CLASS);

    // show and hide the right divs
    // $("#add-items").hide();
    $("#viewer").show();

    this.state.blueprint3d.three.updateWindowSize();

    // set new state
    function handleWindowResize() {
      // $(".sidebar").height(window.innerHeight);
      // $("#add-items").height(window.innerHeight);
    }
    handleWindowResize();

    function reset() {
      // $("texture-context-container").hide();
      $("#wallTextures").hide();
      $("#floorTexturesDiv").hide();
    }

    reset();
    this.setState({ currentStateName: "Design" });

    //change mobx state-active-tab
  }

  async componentDidUpdate(prevProps, prevState, snapShot) {
    if (this.props.addClickListener) {
      // this.setState({ addClickListener: true });
      this.props.store.setClickListener(false);
      this.initItems(this.state.blueprint3d, this.setDesignState);
      // console.log('addClickListener', this.props.addClickListener);
      // await this.addTodo();
      // console.log('history', this.state.history);
      // console.log('currentIndex', this.state.currentIndex);
    }
    if (this.props.addClickListener === false && prevProps.addClickListener) {
      this.setState({ addClickListener: false });
    }
    // const isLoggedIn = this.props.store.getLoggedIn;
    // if (isLoggedIn) {
    //   setTimeout(() => {
    //     this.saveFile();
    //   }, 60000);
    // }
  }

  async getData() {
    localStorage.removeItem("projectName");
    const currentPath = window.location.pathname;
    const parts = currentPath.split("/");

    // let res = await axios.get(
    await axios.get(
      BASE_URL + PLANNAR_SHARE_ENDPOINT + "/" + parts[parts.length - 1]
    ).then((res) => {
      localStorage.setItem("projectName", res.data.name);
      this.setState({
        dataLoaded: true
      });

      let decryptedData = CryptoJS.AES.decrypt(res.data.meta_data, CRYPTOJS_KEY);
      let meta_data = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
      this.state.blueprint3d.model.loadSerialized(meta_data);
    });
  }

  redirectToDashboard() {
    window.location.href = "/dashboard";
  }

  modalSave() {
    this.saveFile("redirect");
  }

  async handelCapture() {
    const isLoggedIn = this.props.store.getLoggedIn;
    MixpanelService.trackEvent("Capture");

    if (isLoggedIn) {
      const viewerDiv = document.getElementById("viewer");
      const canvasElement = viewerDiv.querySelector("canvas");
      const dataURL = canvasElement.toDataURL("image/png");
      await this.setState({
        previewImage: dataURL,
        showPreviewModal: true,
      });
    } else {
      this.setState({ showCaptureModal: true });
    }
  }

  closePreview() {
    this.setState({
      previewImage: null,
      showPreviewModal: false,
    });
  }

  download(imageUrl) {
    MixpanelService.trackEvent("Photo Download");
    const timestamp = Date.now();
    let a = document.createElement("a");
    a.href = imageUrl;
    a.download = `${timestamp}.png`;
    a.click();
  }

  helpModalOpen() {
    MixpanelService.trackEvent("Help");
    this.setState({ helpModal: true });
  }

  helpModalClose() {
    this.setState({ helpModal: false });
  }

  handleCaptureClose() {
    this.setState({ showCaptureModal: false });
  }

  async textureDisplay() {
    if (this.state.textureTouchEvent) {
      MixpanelService.trackEvent("Lock");
      // $("texture-context-container").hide();
      $("#wallTextures").hide();
      $("#floorTexturesDiv").hide();
      $("#context-menu").hide();
      $("#texture-context-menu").hide();
      $("#wall-context-menu").hide();
      $("#color-context-menu").hide();
      $("#items-container").addClass("disable-touch");
      $(".texture-show-hide").addClass("icon-active");
    } else {
      MixpanelService.trackEvent("Unlock");
      $("#items-container").removeClass("disable-touch");
      $(".texture-show-hide").removeClass("icon-active");
    }
    await this.setState((prevState) => ({
      textureTouchEvent: prevState.textureTouchEvent === true ? false : true,
    }));
  }

  isLandscape() {
    this.setState({ isOritentationLandscape: Math.abs(window.orientation) === 90 });
  }

  // redofun = () => {
  //   const { history, currentIndex } = this.state;
  //   console.log('redo', currentIndex < history.length - 1);
  //   if (currentIndex < history.length - 1) {
  //     this.setState({ currentIndex: currentIndex + 1 });
  //     console.log('history, redofun', history);
  //     console.log('redofun', currentIndex);
  //     this.state.blueprint3d.model.loadSerialized(history[currentIndex].items[0].name);
  //   }
  // }

  // undofun = () => {
  //   // let self = this;
  //   const { history, currentIndex } = this.state;
  //   if (currentIndex > 0) {
  //     this.setState({ currentIndex: currentIndex - 1 });
  //     console.log('history, undofun', history);
  //     console.log('undofun', currentIndex);
  //     this.state.blueprint3d.model.loadSerialized(history[currentIndex].items[0].name);
  //     this.addTodo();
  //   }
  // }

  // var orbitControls = blueprint3d.three.controls;
  //   var three = blueprint3d.three;
  //   let self = this;

  //   function zoomIn(e) {
  //     e.preventDefault();
  //     // MixpanelService.trackEvent("zoomIn");
  //     orbitControls.dollyIn(1.1);
  //     orbitControls.update();
  //   }

  //   function zoomOut(e) {
  //     // eslint-disable-next-line no-unused-expressions
  //     e.preventDefault;
  //     // MixpanelService.trackEvent("zoomOut");
  //     orbitControls.dollyOut(1.1);
  //     orbitControls.update();
  //   }

  zoomInFuc(e) {
    var orbitControls = this.state.blueprint3d.three.controls;
    e.preventDefault();
    MixpanelService.trackEvent("zoomIn");
    orbitControls.dollyIn(1.1);
    orbitControls.update();
  }

  zoomOutFuc(e) {
    var orbitControls = this.state.blueprint3d.three.controls;
    e.preventDefault();
    MixpanelService.trackEvent("zoomIn");
    orbitControls.dollyOut(1.1);
    orbitControls.update();
  }

  render() {
    // const { history, currentIndex } = this.state;

    // const canUndo = currentIndex > 0;
    // const canRedo = currentIndex < history.length - 1;
    return (
      <div>
        {/* {this.state.dataLoaded ? ( */}
        <Header clickFunc={this.saveFile} dataLoaded={this.state.dataLoaded} />
        {/* ) : (
          <div>Loading...</div>
        )} */}
        <div className="horizontal-container">
          {/* Left Column */}
          {!this.state.hasViewKey && (
            <div className="sidebar">
              {/* Main Navigation */}
              {isMobile || isTablet ? (
                <div>
                  <div className="floorplan_tab cursor-pointer">
                    <div className="p-2">2D</div>
                  </div>
                  <div className="design_tab cursor-pointer">
                    <div className="p-2">3D</div>
                  </div>
                  <div className="items_tab cursor-pointer">
                    <div className="p-2 text-center">
                      <BiAddToQueue className="plus-icon" />
                    </div>
                  </div>
                  <div className="help_tab cursor-pointer text-center">
                    <div className="p-2 text-center" onClick={() => this.helpModalOpen()}>
                      <IoIosHelpCircle className="help-icon" />
                      <span className="help_text">Help</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="floorplan_tab cursor-pointer">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Floorplan</Tooltip>}
                    >
                      <div className="p-2">2D</div>
                    </OverlayTrigger>
                  </div>
                  <div className="design_tab cursor-pointer">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Design</Tooltip>}
                    >
                      <div className="p-2">3D</div>
                    </OverlayTrigger>
                  </div>
                  <div className="items_tab cursor-pointer">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Add Items</Tooltip>}
                    >
                      <div className="p-2 text-center">
                        <BiAddToQueue className="plus-icon" />
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="help_tab cursor-pointer text-center">
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>Help</Tooltip>}
                    >
                      <div className="p-2 text-center" onClick={() => this.helpModalOpen()}>
                        <IoIosHelpCircle className="help-icon" />
                        <span className="help_text">Help</span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              )}
              <CloseModal
                clickFunc={this.modalSave}
                closeFunc={this.redirectToDashboard}
              />
              <SaveModal blob={this.state.blob} clickFunc={this.saveFile} />
              <LoadModal clickFunc={this.loadFile} />
            </div>
          )}

          {!this.state.hasViewKey && (
            <div>
              {/* Floor Textures */}
              <div id="floorTexturesDiv" style={{ display: "none" }}>
                <FloorTextureModal />
              </div>

              {/* Wall Textures */}
              <div id="wallTextures" style={{ display: "none" }}>
                <WallTextureModal />
              </div>
            </div>
          )}

          {/* Context Menu */}
          {!this.state.hasViewKey && (
            <div id="context-menu">
              <ContextMenu />
            </div>
          )}

          {!this.state.hasViewKey && (
            <div id="texture-context-menu" className="mx-sm-2 mx-md-3">
              <TextureContextMenu />
            </div>
          )}

          {!this.state.hasViewKey && (
            <div id="wall-context-menu" className="mx-sm-2 mx-md-3">
              <WallContextMenu />
            </div>
          )}

          {!this.state.hasViewKey && (
            <div id="color-context-menu" className="mx-sm-2 mx-md-3">
              <ColorCodeContextMenu />
            </div>
          )}

          {!this.state.hasViewKey && (
            <div id="items-container">
              {/* Add Items */}
              <div id="add-items">
                <ItemModal setSelectedCardData={this.setSelectedCardData} />
              </div>
            </div>
          )}

          {/* End Left Column */}

          {/* Right Column */}
          <div className="right-container" onClick={this.handleAddItemCard}>
            {/* 3D Viewer */}
            <div id="viewer"></div>
            <div id="camera-controls-top">
              {isMobile || isTablet ? (
                <div>
                  {this.state.isOritentationLandscape && !isTablet ? (
                    <div className="zoom-controls">
                      <Button
                        variant="btn"
                        size="sm"
                        id="zoom-in"
                        className="basic-button"
                        disabled={this.state.walkthrough === true}
                        onClick={(e) => this.zoomInFuc(e)}
                      >
                        <ZoomIn className="control-icon" />
                      </Button>
                      <span className="separator">|</span>
                      <Button
                        variant="btn"
                        size="sm"
                        id="zoom-out"
                        className="basic-button"
                        disabled={this.state.walkthrough === true}
                        onClick={(e) => this.zoomOutFuc(e)}
                      >
                        <ZoomOut className="control-icon" />
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <div className="mt-2">
                        <Button
                          variant="btn"
                          size="sm"
                          className={"basic-button zoom-in"}
                          disabled={this.state.walkthrough === true}
                          onClick={(e) => this.zoomInFuc(e)}
                        >
                          <ZoomIn className="control-icon" />
                        </Button>
                      </div>
                      <div className="mt-2">
                        <Button
                          variant="btn"
                          size="sm"
                          className={"basic-button zoom-out"}
                          disabled={this.state.walkthrough === true}
                          onClick={(e) => this.zoomOutFuc(e)}
                        >
                          <ZoomOut className="control-icon" />
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="mt-2 mt-sm-1">
                    <Button
                      variant="btn"
                      size="sm"
                      className={"basic-button reset-view"}
                      disabled={this.state.walkthrough === true}
                    >
                      <Loaction className="control-icon" />
                    </Button>
                  </div>
                  <div className="mt-2 mt-sm-1">
                    <Button
                      variant="btn"
                      size="sm"
                      className={"basic-button inperson-view"}
                    >
                      {this.state.walkthrough === false ? (
                        <FaWalking
                          className="control-icon"
                          style={{ fontSize: 24 }}
                        />
                      ) : (
                        <Object className="control-icon" />
                      )}
                    </Button>
                  </div>
                  <div className="mt-2 mt-sm-1">
                    {!this.state.hasViewKey && (
                      <Button
                        variant="btn"
                        size="sm"
                        className={"basic-button texture-show-hide"}
                        onClick={() => this.textureDisplay()}
                      >
                        {this.state.textureTouchEvent ? (
                          <UnlockIcon className="control-icon" />
                        ) : (
                          <LockIcon className="control-icon lock" />
                        )}
                      </Button>
                    )}
                  </div>
                  <div className="mt-2 mt-sm-1">
                    {!this.state.hasViewKey && (
                      <Button
                        variant="btn"
                        size="sm"
                        className={"basic-button capture"}
                        onClick={() => this.handelCapture()}
                      >
                        <FaCamera
                          className="control-icon"
                          style={{ fontSize: 24 }}
                        />
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="mt-2">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Zoom IN</Tooltip>}
                    >
                      <Button
                        variant="btn"
                        size="sm"
                        className={"basic-button zoom-in"}
                        disabled={this.state.walkthrough === true}
                        onClick={(e) => this.zoomInFuc(e)}
                      >
                        <ZoomIn className="control-icon" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="mt-2">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Zoom Out</Tooltip>}
                    >
                      <Button
                        variant="btn"
                        size="sm"
                        className={"basic-button zoom-out"}
                        disabled={this.state.walkthrough === true}
                        onClick={(e) => this.zoomOutFuc(e)}
                      >
                        <ZoomOut className="control-icon" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="mt-2 mt-sm-1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Reset View</Tooltip>}
                    >
                      <Button
                        variant="btn"
                        size="sm"
                        className={"basic-button reset-view"}
                        disabled={this.state.walkthrough === true}
                      >
                        <Loaction className="control-icon" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="mt-2 mt-sm-1">
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Top/Room Scale View</Tooltip>}
                    >
                      <Button
                        variant="btn"
                        size="sm"
                        className={"basic-button inperson-view"}
                      >
                        {this.state.walkthrough === false ? (
                          <FaWalking
                            className="control-icon"
                            style={{ fontSize: 24 }}
                          />
                        ) : (
                          <Object className="control-icon" />
                        )}
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div className="mt-2 mt-sm-1">
                    {!this.state.hasViewKey && (
                      <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>View only / selection</Tooltip>}
                      >
                        <Button
                          variant="btn"
                          size="sm"
                          className={"basic-button texture-show-hide"}
                          onClick={() => this.textureDisplay()}
                        >
                          {this.state.textureTouchEvent ? (
                            <UnlockIcon className="control-icon" />
                          ) : (
                            <LockIcon className="control-icon lock" />
                          )}
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>
                  <div className="mt-2 mt-sm-1">
                    {!this.state.hasViewKey && (
                      <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>Capture Photo</Tooltip>}
                      >
                        <Button
                          variant="btn"
                          size="sm"
                          className={"basic-button capture"}
                          onClick={() => this.handelCapture()}
                        >
                          <FaCamera
                            className="control-icon"
                            style={{ fontSize: 24 }}
                          />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              )}
              {/* <div className="mt-2">
                  <Button
                    variant="btn"
                    size="sm"
                    id="undo"
                    className={"basic-button"}
                    onClick={this.undofun}
                    disabled={!canUndo}
                  >
                    <FaUndo className="control-icon" style={{ fontSize: 24 }} />
                  </Button>
                </div>
                <div className="mt-2">
                  <Button
                    variant="btn"
                    size="sm"
                    id="redo"
                    className={"basic-button"}
                    onClick={this.redofun}
                    disabled={!canRedo}
                  >
                    <FaRedo className="control-icon" style={{ fontSize: 24 }} />
                  </Button>
                </div> */}
            </div>
            <div id="camera-controls-bottom">
              <div id="control-area">
                <div className="arrow up-arrow"></div>
                <div className="arrow down-arrow"></div>
                <div className="arrow left-arrow"></div>
                <div className="arrow right-arrow_1"></div>
                <div id="move-button">Move</div>
              </div>
            </div>

            <div className="powered-by-logo ml-2">
              <a href={MIRRAR_PAGE_URL} target="_blank">
                <ProductByMirrAR width="140" height="100" />
              </a>
            </div>

            {/*2D Floorplanner */}
            <div id="floorplanner">
              <canvas id="floorplanner-canvas"></canvas>
              <div id="floorplanner-controls">
                <button
                  variant="secondary"
                  size="sm"
                  className="icon-text-button"
                  id="move"
                >
                  <MoveIcon className="move-icon mt-auto" />
                  <div className="text-centre fs-12 mt-auto">Move Walls</div>
                </button>
                <button
                  variant="secondary"
                  size="sm"
                  className="icon-text-button"
                  id="draw"
                >
                  <DrawIcon className="draw-icon mt-auto" />
                  <div className="text-centre fs-12 mt-auto">Draw Walls</div>
                </button>
                <button
                  variant="secondary"
                  size="sm"
                  className="icon-text-button"
                  id="delete"
                >
                  <TrashIcon className="trash-icon mt-auto" />
                  <div className="text-centre fs-12 mt-auto">Delete Walls</div>
                </button>
                <button
                  variant="secondary"
                  size="sm"
                  className="icon-text-button"
                  id="update-floorplan"
                >
                  <CircleTickIcon className="circle-tick-icon mt-auto" />
                  <div className="text-centre fs-12 mx-4 mt-auto">Done</div>
                </button>
              </div>
              <div id="draw-walls-hint">
                Press the "Esc" key to stop drawing walls
              </div>
            </div>
          </div>
          {/* End Right Column */}

          {this.state.showPreviewModal && (
            <div className="image-container">
              <div className="download-container">
                <div className="download-title">
                  <h2 className="text-center">Download Photo</h2>
                </div>
                <div className="download-close-btn">
                  <Button
                    variant="none"
                    className="save-close-btn cursor-pointer"
                    onClick={() => this.closePreview()}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </div>
              <img alt="Img" className="image" src={this.state.previewImage} />
              <div className="text-center my-auto">
                <Button
                  variant="none"
                  className="save-close-btn cursor-pointer"
                  onClick={() => this.download(this.state.previewImage)}
                >
                  <FaDownload style={{ color: "#FFFFFF" }} /> Download
                </Button>
              </div>
            </div>
          )}

          {this.state.helpModal && (
            <div id="help-container">
              <Button
                variant="none"
                className="help-close-btn cursor-pointer"
                onClick={() => this.helpModalClose()}
              >
                <CloseIcon />
              </Button>
              <iframe
                src="https://buldit.tawk.help/"
                className="help-contents"
              ></iframe>
            </div>
          )}

          <Modal
            size="sm"
            aria-labelledby="capture-modal"
            centered
            show={this.state.showCaptureModal}
            onHide={() => this.handleCaptureClose()}
            id="capture-modal"
            className="fs-14"
          >
            <Modal.Header className="d-flex justify-content-end p-0">
              <Button
                variant="none"
                className="cursor-pointer"
                onClick={() => this.handleCaptureClose()}
              >
                <CloseIcon />
              </Button>
            </Modal.Header>
            <Modal.Body className="p-2">
              <div className="modal-plain-text">
                To capture a photo please login / signup
              </div>
            </Modal.Body>
          </Modal>

          <div id="items_count_container" style={{ display: 'none' }}>
            <div id="items_count" style={{ display: 'none' }}></div>
            <div className="items_count_loader">
              <img src="/spinner.gif"></img>
              <div>LOADING</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BlueprintPage);
