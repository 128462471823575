import "../App.css";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";

class TextureContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 60.96,
      height: 60.96,
    };
  }

  handleWidthChange = (e) => {
    this.setState({ width: e.target.value });
  };

  handleHeightChange = (e) => {
    this.setState({ height: e.target.value });
  };

  resetState = () => {
    this.setState({
      width: 60.96,
      height: 60.96,
    });
  };

  render() {
    const { width, height } = this.state;

    const isButtonDisabled = width === "" || height === "";
    
    return (
      <div>
        <div className="texture-panel-heading">
          <div className="panel-heading">Adjust Size (Optional)</div>
          <hr className="small-underline" />
          <div className="panel-body">
            <div className="form-horizontal">
              <div className="form-group">
                <label className="control-label">Width (cm)</label>

                <input
                  type="number"
                  className="form-control"
                  id="texture-width"
                  value={width}
                  onChange={this.handleWidthChange}
                ></input>
              </div>
              <div className="form-group">
                <label className="control-label">Height (cm)</label>

                <input
                  type="number"
                  className="form-control"
                  id="texture-height"
                  value={height}
                  onChange={this.handleHeightChange}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="btn"
          size="sm"
          block
          id="apply-texture"
          className="outline-button"
          disabled={isButtonDisabled}
        >
          <span className="text-centre">Apply Texture</span>
        </Button>
        <Button
          id="reset-texture"
          onClick={this.resetState}
          className="d-none"
        ></Button>
      </div>
    );
  }
}

export default TextureContextMenu;