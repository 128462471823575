import React from "react";

const RightEllipse = ({ width = 30, height = 50 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.489 2.74294C18.6049 1.92974 20.1738 2.17222 20.9364 3.32322C27.2899 12.9127 30.5477 24.2465 30.2316 35.7859C29.9156 47.3252 26.0424 58.4637 19.1735 67.6911C18.3491 68.7986 16.7692 68.9548 15.6996 68.0818L1.99309 56.8945C0.923445 56.0215 0.772702 54.4515 1.56309 53.3194C5.27855 47.9976 7.36905 41.6889 7.54773 35.1646C7.72642 28.6403 5.98436 22.2266 2.56575 16.7095C1.83851 15.5358 2.07496 13.9764 3.19079 13.1632L17.489 2.74294Z"
        fill="#384350"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8458 35.6478C22.104 35.9041 22.2499 36.2524 22.2515 36.6162C22.2532 36.98 22.1103 37.3295 21.8544 37.588L16.701 42.7891C16.4446 43.0477 16.096 43.1938 15.7319 43.1953C15.3678 43.1969 15.018 43.0537 14.7594 42.7973C14.5009 42.5409 14.3548 42.1923 14.3532 41.8282C14.3517 41.4641 14.4949 41.1143 14.7513 40.8557L18.9388 36.6308L14.714 32.4433C14.4627 32.1856 14.3226 31.8395 14.3239 31.4795C14.3253 31.1196 14.468 30.7746 14.7213 30.5188C14.9746 30.263 15.3182 30.1169 15.6781 30.112C16.038 30.1072 16.3855 30.2438 16.6456 30.4926L21.8467 35.6469L21.8458 35.6478Z"
        fill="white"
      />
    </svg>
  );
};

export default RightEllipse;
